import Pusher from 'pusher-js'

if ('Notification' in window) {
  Notification.requestPermission()
}

export default {
  install(app) {
    window.Pusher = new Pusher(import.meta.env.VITE_PUSHER_APP_KEY, {
      cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
      encrypted: true,
    })
  },
}
