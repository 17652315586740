import { createI18n } from 'vue-i18n'
import messages from '../Generated/translations.json'

export default createI18n({
  locale: 'fr',
  fallbackLocale: 'fr',
  silentTranslationWarn: true,
  messages,
  legacy: false,
  globalInjection: true,
})
