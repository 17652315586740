import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'

// Root to path to remove for search
const patternsToRemove = [
  '/resources/js/Pages/',
  '/vendor/deliverup/access/resources/js/pages/'
]

// Get paths for both app and access page components
function getPaths() {
  return {
    ...import.meta.glob('/resources/js/Pages/**/*.vue'),
    ...import.meta.glob('/vendor/deliverup/access/resources/js/pages/**/*.vue')
  }
}

// Rebuild a component pages tree using name as key
function formalizePaths(paths) {
  return Object.keys(paths).map(path => {
    let screenName = path

    // Remove base path patterns
    patternsToRemove.forEach(
      pattern => (screenName = screenName.replace(pattern, ''))
    )

    // Remove extension
    screenName = screenName.replace(/\.[a-z]*/, '')

    // Replace / by .
    screenName = screenName.replaceAll('/', '.')

    return { name: screenName, path }
  })
}

// Extract real path for page component name
function getPagePath(name, paths) {
  return formalizePaths(paths).find(path => path.name === name)?.path
}

export default name => {
  const paths = getPaths()

  return resolvePageComponent(getPagePath(name, paths), paths)
}
